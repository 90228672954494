import { graphql } from 'gatsby';
import React from 'react';
import Layout from 'components/Layout';
import HeroPlain from 'components/blocks/HeroBanner/HeroPlain';
import ApplyForm from 'components/talentlink/applyForm';
import JobSearchScripts from 'components/talentlink/scripts';
import { JobSearchJourneyPage } from 'components/talentlink/types';
import { processPageMeta } from 'helpers/csTypeProcessors';
import { PageMeta } from 'types/contentStack';

type Props = {
  data: JobApplicationPageData;
};

const JobApplicationPage: React.FC<Props> = ({ data }) => {
  const page = data.csJobApplicationPage;
  const { meta, breadcrumbs } = processPageMeta(page.page_meta, '');
  return (
    <Layout
      url={page.url}
      pageMetadata={meta}
      breadcrumbs={breadcrumbs}
      locale={data.csJobApplicationPage.locale}>
      <HeroPlain hero_plain={{ heading: page.header, subheading: null }} />
      <JobSearchScripts page={JobSearchJourneyPage.APPLY} />
      <ApplyForm />
    </Layout>
  );
};

export default JobApplicationPage;

type JobApplicationPageData = {
  csJobApplicationPage: {
    locale: string;
    header: string;
    page_meta: PageMeta;
    url: string;
  };
};

export const query = graphql`
  query($id: String!) {
    csJobApplicationPage(id: { eq: $id }) {
      locale
      header
      page_meta {
        title
        description
      }
    }
  }
`;
