import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  colors,
  themeSpecificColors,
} from '@rsa-digital/evo-shared-components/theme/RSA/colors';
import { fonts } from '@rsa-digital/evo-shared-components/theme/RSA/fonts';
import styled, { createGlobalStyle } from 'styled-components';
import talentLinkGlobalStyles, { secondaryButton } from '../styles';

export const NormaliseStyles = createGlobalStyle`
/* the TalentLink JS imports a script which sets the background-color to a grey colour which we need to overwrite */
  body {
    background-color: ${colors.neutral08} !important;
  }
`;

const ApplyFormContainer = styled.div`
  ${talentLinkGlobalStyles};

  /* & below used to ensure higher priority than styles imported by JS */
  &&&&&&& {
    ${fonts.paragraphSmall};

    .tlk-components-vanilla-apply-content {
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .apply-main-container {
      width: 100%;
      margin: 0;
      float: none;
    }

    .application-content {
      margin: 0;
      width: 100%;
    }

    .apply-application-title-row {
      ${fonts.headingMedium};
      margin-top: 0;
      margin-bottom: ${spacing(4)};
    }

    form,
    .apply-main-fieldset-section-component {
      padding-left: 0;
      padding-right: 0;
      background-color: unset;
      box-shadow: none;
      border: none;
    }

    h1 {
      text-align: left;
    }

    h2,
    h3 {
      ${fonts.headingSmall};
      min-height: unset;
      text-transform: unset;
      margin-top: 0;
      margin-bottom: ${spacing(4)};
    }

    .apply-answer-row-label,
    label {
      ${fonts.headingXSmall};
    }

    input,
    .text-required,
    .apply-no-answer,
    #legend_where_you_referred__9 .apply-answer-row-label {
      ${fonts.paragraph};
    }

    .text-required {
      color: unset;
    }

    .help-block-container {
      background-color: unset;

      .help-block {
        background-color: unset;
        border: none;
      }

      .help-block-message-container {
        padding: ${spacing(0.5)};
        color: ${colors.notificationError};
      }
    }

    .alert-danger {
      color: ${colors.neutral01};
    }

    .form-control {
      border-color: ${colors.neutral03} !important;
    }

    #apply-label-description-phone__mobile__6 {
      ${fonts.headingXSmall};
    }

    fieldset {
      background-color: unset;
    }
    .apply-no-answer {
      font-weight: 400 !important;
    }

    .apply-form {
      padding-top: 0;
    }

    .radio-inline {
      display: none;
    }

    input,
    select,
    textarea {
      background-color: ${colors.neutral08} !important;
    }

    select {
      margin-bottom: 0;
    }

    .lum-apply-section__name {
      margin-bottom: ${spacing(4)};
    }

    .apply-widget-el {
      padding: 0;
    }

    .lumesseButtonsContainer {
      margin-top: ${spacing(4)};
      margin-bottom: ${spacing(8)};
    }

    .lumesse-apply-widget-submit,
    .lumesseBottomActionBar {
      position: relative;
    }

    .lumesseBottomActionBar {
      box-shadow: none;
      padding: 0;
    }

    #submit-button {
      ${fonts.paragraph}
      flex-grow: 0;
      width: unset;
    }

    div[aria-labelledby='legend_where_you_referred__9'] {
      padding-top: 0;
    }

    .subQuestionsForsocial_networking_and_instant_messaging_accounts {
      padding: 0;
    }

    #section-legend_Step_1_Section_0,
    #section-legend_Step_2_Section_0 {
      margin-bottom: ${spacing(1)};

      h2 {
        margin-bottom: 0;
      }
    }

    .char-counter {
      padding-top: ${spacing(1)};
    }

    .char-counter-txt {
      display: none;
    }

    .apply-attached-document-label,
    .apply-answer-row-label {
      display: flex;
      justify-content: space-between;
    }

    input[type='radio']:checked + .radio-inline-label {
      &:before {
        border-color: ${themeSpecificColors.core01_60};
      }
      &:after {
        background: ${colors.core01};
      }
    }

    .radio-inline-label {
      ${fonts.paragraph};
    }

    .radio-inline-label {
      &:before {
        top: 6px;
        outline: none;
        border-color: ${colors.neutral03};
      }
      &:after {
        top: 11px;
      }
    }

    .drag-and-drop-wcag,
    .apply-remove-button-container {
      button {
        ${secondaryButton}
        min-height: unset;
        padding: ${spacing(0.5)} ${spacing(3)};
      }
    }

    .drop-message {
      button {
        margin-right: ${spacing(1)};
      }
    }

    .drop-icon {
      line-height: 24px;

      .material-icons {
        cursor: pointer;
        color: ${colors.core01};
      }
    }

    .apply-filename-image-ext {
      color: ${colors.core01};
    }

    .tlk-apply-components-styling-context a {
      color: ${colors.core01};
    }

    .apply-fileitem-container {
      display: flex;
      align-items: center;

      .apply-filename-block {
        margin-right: ${spacing(1)};
      }
    }

    .apply-filename-container {
      margin: 0;
    }

    .tlk-apply-components-styling-context
      .apply-drag-drop-area
      .apply-fileitem-container
      .apply-remove-button-container
      .apply-remove-button {
      line-height: 24px;

      &:before {
        font-size: 16px;
        line-height: 16px;
        color: ${colors.core01};
      }
    }

    .lumesse-apply-widget-dps,
    .lumesse-apply-widget-section {
      legend {
        margin: 0;

        h2 {
          margin: 0;
        }
      }
    }

    .lumesse-apply-widget-dps {
      form,
      .lum-apply-section {
        margin: 0;
        padding: 0;
      }
    }

    #legend_where_you_referred__9 {
      margin-left: -5px;
    }

    select,
    input {
      ${fonts.paragraph};
      height: unset;
    }

    .apply-messages {
      padding-bottom: ${spacing(8)};
    }

    & {
      .form-control-date select {
        ${fonts.paragraphSmall}
      }
    }

    .tlk-apply-components-styling-context
      .form-control-date
      .form-control-date-year:before,
    .form-control-date-month:before,
    .form-control-date-day:before {
      top: 50px;
    }

    .form-dropdown-arrow::before,
    .apply-select-container::before {
      top: 50%;
      transform: translateY(-50%);
    }

    .apply-widget-el {
      overflow: unset;
    }

    .select-input {
      option::selection,
      option:checked {
        background-color: ${colors.core01};
      }
    }

    .form-control-country-code {
      /* 
        The spacing of this form field is different to the rest due to needing a margin beneath it. 
        The value in top here is linked to margin bottom 
      */
      &::before {
        top: calc(50% - ${spacing(1)});
      }
      #country-code_phone__mobile__6 {
        margin-bottom: ${spacing(2)};
      }
    }

    #section-legend_Step_0_Section_0 {
      margin-bottom: ${spacing(4)};
    }

    .social_networking_and_instant_messaging_accounts h3 {
      display: none;
    }

    .checkbox-inline-label {
      display: flex;
      align-items: center;

      &:before {
        outline: none;
        border-color: ${colors.neutral03};
      }

      &:after {
        transform: translate(0.25em, 0) rotate(-45deg);
      }

      .choice-input-label {
        ${fonts.paragraph}
      }
    }

    input[type='checkbox']:checked + .checkbox-inline-label:before {
      border-color: ${themeSpecificColors.core01_60};
    }

    .apply-submission-accepted {
      text-align: left;
      margin: 0;

      .apply-message-submission-success {
        ${fonts.headingMedium}
      }

      .apply-message-aplication-received {
        ${fonts.paragraphLarge}
      }

      hr {
        color: transparent;
        margin: ${spacing(2)} 0;
      }
    }

    textarea {
      height: 160px;
    }

    .subQuestionsForsocial_networking_and_instant_messaging_accounts {
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }

    .panel {
      box-shadow: none;
    }
  }
`;

export default ApplyFormContainer;
