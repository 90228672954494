import { Script } from 'gatsby';
import React from 'react';
import ApplyFormContainer, { NormaliseStyles } from './styles';

const ApplyForm: React.FC = () => (
  <>
    <NormaliseStyles />
    <Script src="https://code.jquery.com/jquery-1.12.3.min.js" />
    <Script
      src="https://emea3.recruitmentplatform.com/apply-app/static/apply/release/EDGE/apply-all-in-one-namespaced.js"
      data-talentlink-apply
      data-talentlink-apply-host="https://emea3.recruitmentplatform.com"
      data-talentlink-apply-config-key="AAACOQAA-3476ead1-51f0-4725-ab49-fa61e5d0c643"
    />
    <ApplyFormContainer>
      <main data-talentlink-apply-root-container />
    </ApplyFormContainer>
  </>
);

export default React.memo(ApplyForm);
